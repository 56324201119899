import React from "react"

import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import Page from '../components/page'

let Index = ({data}) => (
  <Layout>
    <Seo pageTitle={data.page?.title} titleTemplate='%s'/>
    {data.page && <Page {...data.page} />}
  </Layout>
)

export const query = graphql`
  query FrontpageQuery {
    page: sanityPage(slug: {current: {regex: "/frontpage/"}}) {
      id
      title
      slug {
        current
      }
      _rawContent(resolveReferences: {maxDepth: 1000})
      pageHeader
      bannerImage {
        asset {
          gatsbyImageData
        }
      }
    }
  }
`;

export default Index
